import { graphql } from "gatsby";
import React from "react";
import ContactForm from "../components/AllModules/ContactForm/ContactForm";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import SEO from "../components/seo";
import StaticBanner from "../components/StaticBanner/StaticBanner";

const NewsTemplate = (props) => {
    const { insight, siteConfig, blog } = props.data.glstrapi;
    var insightData = insight;
    if(blog){
        insightData = blog;   
        insightData.Category =  { id: 'blog', Category: "Blogs" }
        insightData.Content_with_Image = [{
            Content : blog.Content,
            Images: [],
            Title:null
        }]
    } 
    return (
        <div>
            <SEO
                title={insightData.Title}
                description={`Read about ${insightData.Title} here and stay updated about the latest happenings in Dubai real estate.`}
                location={props.location}
            />
            <Header/>
            <StaticBanner
                news={true}
                data={insightData}
                modules={insightData?.Content_with_Image}
                siteConfig={siteConfig}
            />
            <ContactForm/>
            <Footer
                popularSearch="Popular_Static"
            />
        </div>
    )
}

export default NewsTemplate;

export const pageQuery = graphql`
    query newsQuery($id: ID!) {
        glstrapi {
            insight(id: $id, publicationState: LIVE) {
                id
                Title
                Meta_Title
                Meta_Description
                Banner_Image {
                    url
                    alternativeText
                }
                Category {
                    Category
                    id
                }
                Published_Date
                Url
                Speak_with_Team: Team_to_Contact {
                    id
                    Name
                    Phone
                    Email
                    Designation
                    Tile_Image {
                        url
                        alternativeText
                    }
                    Team_Category {
                        Title
                    }
                }
                Content_with_Image {
                    id
                    Title
                    Images {
                        url
                        alternativeText
                    }
                    Content
                }
            }
            blog(id: $id, publicationState: LIVE) {
                id
                Title
                Meta_Title
                Meta_Description
                Banner_Image: Image {
                    url
                    alternativeText
                } 
                Published_Date: published_at
                Url 
                Content
            }
            siteConfig {
                Office_Phone
                Office_Image {
                    url
                    alternativeText
                }
                Office_Address
                Office_Email
                Office_Whatsapp
            }
        }
    }
`